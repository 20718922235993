import Vue from 'vue'
import VueRouter from 'vue-router'
import Publish from '../views/Publish.vue'
import Ingest from '../views/Ingest.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/publish',
    name: 'Publish',
    component: Publish
  },
  {
    path: '/ingest',
    name: 'Ingest',
    component: Ingest
  },
  {
    path: '/manage/users',
    name: 'User Management',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/UserManagement.vue')
  },
  {
    path: '/manage/emailconf',
    name: 'Email Management',
    component: () => import('../views/EmailManagement.vue')
  },
  {
    path: '/manage/ingestgrundstock',
    name: 'Grundstock Import',
    component: () => import('../views/GrundstockImport.vue')
  },
  {
    path: '/manage/agencyassignmentrules',
    name: 'Agency Assignment Rules',
    component: () => import('../views/AgencyRulesManagement.vue')
  },
  {
    path: '/manage/jasperfields',
    name: 'Jasper Field Mapping',
    component: () => import('../views/JasperFieldsManagement.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
