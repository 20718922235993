import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'
import api from '@/backendapi'
import { formatDateAsStr } from '@/util'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    /**
     * Used to prevent accidental navigation away from page.
     * 
     * See App.vue for the event handler that uses this.
     */
    uiVersion: process.env.APP_VERSION || '0',
    apiVersion: "1.0.0",
    leaveGuard: false,
    user: {},
    userList: [],
    categoryOptions: [
      { value: null, text: "Categories loading...", disabled: true },
    ],
    agencyOptions: [
      { value: null, text: "Agencies loading...", disabled: true },
    ],
    agencyRulesList: [],
    jasperFieldsList: [],
    show_food: process.env.VUE_APP_SHOW_FOOD,
    show_hardware: process.env.VUE_APP_SHOW_HARDWARE
  },
  mutations: {
    setLeaveGuard(state, enable) {
      state.leaveGuard = enable
    },
    setUser(state, payload) {
      /**
       * Payload is expected to be a decoded JWT object.
       */
      let user = {
        username: payload.username,
        role: payload.role,
        tokenExpiry: payload.exp  // Timestamp
      }
      state.user = user
    },
    setUserList(state, payload) {
      state.userList = payload;
    },
    setApiVersion(state, versionStr) {
      state.apiVersion = versionStr
    },
    setCategoryOptions(state, categoryOptions) {
      state.categoryOptions = categoryOptions
    },
    setAgencyOptions(state, agencyOptions) {
      state.agencyOptions = agencyOptions
    },
    setAgencyRulesList(state, agencyRulesList) {
      agencyRulesList.forEach(ag => {
        let dateStr = formatDateAsStr(new Date(ag.start_date));
        ag.start_date = dateStr
        if (ag.end_date) {
          let dateStr = formatDateAsStr(new Date(ag.end_date));
          ag.end_date = dateStr
        }
      });
      state.agencyRulesList = agencyRulesList
    },
    setJasperFieldsList(state, jasperFieldsList) {
      state.jasperFieldsList = jasperFieldsList
    }
  },
  actions: {
    async login(store, payload) {
      //let payload = { username: username, password: password };
      try {
        const res = await api.postLogin(payload)
        let token = res.token
        localStorage.setItem('token', token);
        let tokenData = VueJwtDecode.decode(token)
        store.commit("setUser", tokenData);
        store.dispatch("getApiVersion");
        store.dispatch("getCategories")
      } catch (err) {
        throw (err.response)
      }
    },
    async logout(store) {
      localStorage.removeItem('token')
      store.dispatch('logoutClearState')
    },
    /**
     * Clear all Vuex state that could be populated from user action.
     * 
     * @param {*} state 
     */
    logoutClearState(store) {
      store.commit("setUser", {});
      store.commit("setUserList", []);
      let options = [
        { value: null, text: "Select a category", disabled: true }
      ]
      store.commit("setCategoryOptions", options)
    },
    async getUsers(store) {
      try {
        const res = await api.getUsers(localStorage.token)
        store.commit("setUserList", res);
        return res
      } catch (err) {
        throw (err.response)
      }
    },
    // async getSgGroups() {
    //   try {
    //     return await api.getSgGroups(localStorage.token)
    //   } catch (err) {
    //     throw (err.response)
    //   }
    // },
    async registerUser(store, payload) {
      //let payload = { username, password, role };
      try {
        return await api.postRegister(localStorage.token, payload)
      } catch (err) {
        throw (err.response)
      }
    },
    async changePassword(store, payload) {
      //let payload = { username, password };
      try {
        return api.postChangePassword(localStorage.token, payload)
      } catch (err) {
        throw (err.response)
      }
    },
    async modifyUser(store, payload) {
      //let payload = { username, password };
      try {
        return api.postModifyUser(localStorage.token, payload)
      } catch (err) {
        throw (err.response)
      }
    },
    async deleteUser(store, payload) {
      try {
        return api.postDeleteUser(localStorage.token, payload)
      } catch (err) {
        throw (err.response)
      }
    },
    async printProfile() {
      try {
        const res = await api.getProfile(localStorage.token)
        return res
      } catch (err) {
        throw (err.response)
      }
    },
    async getApiVersion(store) {
      api.getApiVersion(localStorage.token).then(version => {
        store.commit('setApiVersion', version)
      });
    },
    async getCategories(store) {
      let categories = await api.getAllowedProjects(localStorage.token)
      let options = [
        { value: null, text: "Select a category", disabled: true },
      ]
      for (let cat of categories) {
        options.push({ value: cat.toLowerCase(), text: cat })
      }
      store.commit("setCategoryOptions", options)
    },
    async getAgencies(store) {
      let agencies = await api.get3DAgencies(localStorage.token)
      let options = [
        { value: null, text: "<No selection>" },
      ]
      for (let ag of agencies) {
        options.push({ value: ag, text: ag })
      }
      store.commit("setAgencyOptions", options)
    },
    async getAgencyRules(store, payload) {
      try {
        const res = await api.getAgencyRules(localStorage.token, payload)
        store.commit("setAgencyRulesList", res);
        return res
      } catch (err) {
        throw (err.response)
      }
    },
    async createAgencyRule(store, payload) {
      //let payload = { brand, price_category, agency_name, start_date, end_date };
      try {
        return await api.postCreateAgencyRule(localStorage.token, payload)
      } catch (err) {
        throw (err.response)
      }
    },
    async disableAgencyRule(store, ruleId) {
      try {
        const res = await api.postDisableAgencyRule(localStorage.token, ruleId)
        return res
      } catch (err) {
        throw (err.response)
      }
    },
    async disableAllAgencyRules() {
      try {
        const res = await api.postDisableAllAgencyRules(localStorage.token)
        return res
      } catch (err) {
        throw (err.response)
      }
    },
    async getJasperFields(store) {
      try {
        const res = await api.getJasperFields(localStorage.token)
        store.commit("setJasperFieldsList", res);
      } catch (err) {
        throw (err.response)
      }
    },
    async createJasperField(store, payload) {
      try {
        const res = await api.postCreateJasperField(localStorage.token, payload)
        return res
      } catch (err) {
        throw (err.response)
      }
    },
    async deleteJasperField(store, fieldId) {
      try {
        const res = await api.postDeleteJasperField(localStorage.token, fieldId)
        return res
      } catch (err) {
        throw (err.response)
      }
    },
  },
  modules: {
  }
})
