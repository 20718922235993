<template>
  <b-navbar toggleable="md" variant="light" id="nav">
    <b-navbar-brand>Delivery Tool</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item v-if="canIngest" :to="'/ingest'">Ingest</b-nav-item>
        <b-nav-item v-if="canPublish" :to="'/publish'">Publish</b-nav-item>
        <b-nav-item-dropdown v-if="canManageUsers" text="Manager Actions">
          <b-dropdown-item :to="'/manage/users'">Manage Users</b-dropdown-item>
          <b-dropdown-item v-if="isFoodEnvironment" :to="'/manage/emailconf'">Email Whitelist</b-dropdown-item>
          <b-dropdown-item v-if="isFoodEnvironment" :to="'/manage/agencyassignmentrules'">Agency Assignment Rules</b-dropdown-item>
          <b-dropdown-item v-if="isFoodEnvironment" :to="'/manage/ingestgrundstock'">Grundstock Import</b-dropdown-item>
          <b-dropdown-item v-if="isHardwareEnvironment" :to="'/manage/jasperfields'">Jasper Fields</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item v-if="!loggedIn" :to="'/login'">Login</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown v-if="loggedIn" right>
          <template #button-content>{{user.username}} ({{user.role}})</template>
          <b-dropdown-item @click="showModifyPassword">Change Password</b-dropdown-item>
          <b-dropdown-item @click="doLogout">Logout</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item disabled>UI version: {{ uiVersion }}</b-dropdown-item>
          <b-dropdown-item disabled>API version: {{ apiVersion }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <b-modal
      ref="modifyPasswordModal"
      title="Change my password"
      hide-header-close
      @ok="changePassword"
      :ok-disabled="!changeFormIsValid"
    >
      <label>Username:</label>
      <b-form-input v-model="user.username" disabled></b-form-input>
      <label>Current password:</label>
      <b-form-input v-model="password" type="password"></b-form-input>
      <b-form-group
        label="New password:"
        label-for="newPassword"
        :state="changeUserPasswordState"
        :invalid-feedback="changeUserPasswordFeedback"
      >
        <b-form-input id="newPassword" v-model="newPassword" type="password"></b-form-input>
      </b-form-group>
      <b-form-group
        label="New password (repeat):"
        label-for="newPasswordChange"
        :state="changeUserPasswordConfirmState"
        :invalid-feedback="changeUserPasswordConfirmFeedback"
      >
        <b-form-input id="newPasswordChange" v-model="newPasswordConfirm" type="password"></b-form-input>
      </b-form-group>
    </b-modal>
  </b-navbar>
</template>

<script>
import { ROLES } from "@/const";
import { defaultToastBody, defaultToastConfig } from "@/util";

const VALID_INGEST_ROLES = [ROLES.ADMIN, ROLES.MANAGER];
const VALID_PUBLISH_ROLES = [
  ROLES.ADMIN,
  ROLES.MANAGER,
  ROLES.SUPPLIER,
  ROLES.AGENCY
];
const VALID_USERMANAGE_ROLES = [ROLES.ADMIN, ROLES.MANAGER];

export default {
  name: "Navigation",
  data: function() {
    return {
      password: "",
      newPassword: "",
      newPasswordConfirm: ""
    };
  },
  computed: {
    isFoodEnvironment() {
      return this.$store.state.show_food;  // Truthy value
    },
    isHardwareEnvironment() {
      return this.$store.state.show_hardware;  // Truthy value
    },
    uiVersion() {
      return this.$store.state.uiVersion;
    },
    apiVersion() {
      return this.$store.state.apiVersion;
    },
    user: function() {
      return this.$store.state.user;
    },
    loggedIn: function() {
      return this.$store.state.user.role ? true : false;
    },
    canIngest: function() {
      let isAllowed = this.$store.state.user.role
        ? VALID_INGEST_ROLES.includes(this.$store.state.user.role)
        : false;
      return isAllowed && this.isHardwareEnvironment;
    },
    canPublish: function() {
      return this.$store.state.user.role
        ? VALID_PUBLISH_ROLES.includes(this.$store.state.user.role)
        : false;
    },
    canManageUsers: function() {
      return this.$store.state.user.role
        ? VALID_USERMANAGE_ROLES.includes(this.$store.state.user.role)
        : false;
    },
    userIsAdmin: function() {
      return this.$store.state.user.role === ROLES.ADMIN;
    },
    changeUserPasswordState() {
      return this._validatePassword(this.newPassword);
    },
    changeUserPasswordFeedback() {
      return this.newPassword.length === 0
        ? "Required"
        : "Must be at least 8 characters";
    },
    changeUserPasswordConfirmState() {
      return this.newPasswordConfirm
        ? this.newPasswordConfirm === this.newPassword
        : false;
    },
    changeUserPasswordConfirmFeedback() {
      if (!this.newPasswordConfirm) {
        return "Required";
      }
      if (this.newPasswordConfirm != this.newPassword) {
        return "Passwords do not match";
      }
      return "";
    },
    changeFormIsValid() {
      return (
        this._validatePassword(this.newPassword) &&
        this.newPassword == this.newPasswordConfirm
      );
    },
  },
  methods: {
    _validatePassword: function(password) {
      return password.length >= 8;
    },
    doLogout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    printProfile: function() {
      this.$store.dispatch("printProfile").then(data => {
        console.log(data);
      });
    },
    showModifyPassword: function(username, userGroup) {
      this.password = "";
      this.newPassword = "";
      this.newPasswordConfirm = "";
      this.selectedGroup = userGroup;
      this.$refs["modifyPasswordModal"].show();
    },
    changePassword: function() {
      let payload = {
        password: this.password,
        new_password: this.newPassword
      };
      this.$store
        .dispatch("changePassword", payload)
        .then(() => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "Password changed";
          toastConfig.variant = "primary";
          this.$bvToast.toast(
            `Successfully changed password, please log back in.`,
            toastConfig
          );
          this.password = "";
          this.newPassword = "";
          this.newPasswordConfirm = "";
          this.doLogout();
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    }
  }
};
</script>